import { FiStar, FiAlignJustify, FiPlus } from "react-icons/fi"
import { Button } from "react-bootstrap";

const QuoteNavbar = () => {
    return (
        <div className="navContainer">
            <div className='mynavbar'>
                <Button variant="Light" href="/" className="btn btn-lg"><FiStar /></Button>
                <Button variant="Light" href="/add" className="btn btn-lg"><FiPlus /></Button>
                <Button variant="Light" href="/all" className="btn btn-lg"><FiAlignJustify /></Button>
            </div>
        </div>
    );
}

export default QuoteNavbar;