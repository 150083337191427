import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../firebase/config";

const initState = {
    quote: "",
    person: "",
    source: "",
}

export const Add = (props) => {
    // Using a hook to populate the form before submission
    const [quoteDetails, setQuoteDetails] = useState(initState)

    // Handling the submission, running postQuote async
    const handleSubmit = async () => {
        await postQuote(quoteDetails);
        setQuoteDetails(initState);
    }

    // Adding user's input to quoteDetails on every keystroke
    const handleChange = (e) => {
        e.persist();
        setQuoteDetails((oldState) => ({
            ...oldState,
            [e.target.name]: e.target.value,
        }))
    }

    // The actual posting to the firestore
    const postQuote = async quoteDetails => {
        const time = Date.now()
        await addDoc(collection(firestore, "quotes"), {
            ...quoteDetails,
            postedOn: new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', minute: '2-digit'}).format(time),
        })
    };

    return (
        <div className="mainContainer">
            <div className="innerContainer">
                <div className="addQuoteForm">
                    <Form className="addQuoteForm">
                        <Form.Group controlId="Input1">
                            <Form.Label>Add your quote</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="quote"
                                value={quoteDetails.quote}
                                placeholder="Quote"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group controlId="Input2">
                            <Form.Label>Who was the original thinker?</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="person"
                                value={quoteDetails.person}
                                placeholder="Person"
                            />
                        </Form.Group>
                        <Form.Group controlId="Input3">
                            <Form.Label>Add a link to where the quote is from</Form.Label>
                            <Form.Control
                                onChange={handleChange}
                                name="source"
                                value={quoteDetails.source}
                                placeholder="Link"
                            />
                        </Form.Group>
                    </Form>
                    <Button variant="light" className="addQuoteButton" onClick={handleSubmit}>
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    )
}