import { Container } from "react-bootstrap";

export const BookCard = (props) => {
    return (
            <div className="quoteList">
                <h4 className="listQuote">"{props.quote}"</h4>
                <Container className="secondLine">
                    <div className="listPerson">
                        {props.person}
                    </div>
                    <div className="listLink">
                        {props.source && (
                            <a href={props.source} target="_blank" rel="noreferrer">Source</a>
                        )}
                    </div>
                </Container>
            </div>
    )
}