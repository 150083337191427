import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Quote } from './pages/quote';
import All from './pages/all';
import { Add } from './pages/add';
import QuoteNavbar from './components/navbar/QuoteNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className='mainDiv'>
      <QuoteNavbar />
      <Router>
        <Routes>
          <Route path='/' exact element={<Quote/>}/>
          <Route path='/all' exact element={<All/>}/>
          <Route path='/add' element={<Add/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;