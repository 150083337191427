// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { 
//   getFirestore,
//   query,
//   orderBy,
//   onSnapshot,
//   collection,
//   getDoc, 
//   getDocs, 
//   addDoc,
//   updateDoc,
//   doc, 
//   serverTimestamp, 
//   arrayUnion
// } from "firebase/firestore";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCaH1_nHqm6lAWKIfBUFuYnEa5Jh983-Yw",
  authDomain: "sitre-io.firebaseapp.com",
  projectId: "sitre-io",
  storageBucket: "sitre-io.appspot.com",
  messagingSenderId: "436739631851",
  appId: "1:436739631851:web:1bcb8d7e268bd96c066cae",
  measurementId: "G-P0F2B7Y754"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app)

export const authenticateAnonymously = () => {
  return signInAnonymously(getAuth(app));
};

export const fetchQuotes = async () => {
      const req = collection(firestore, 'quotes');
      const second = await getDocs(req);
      const quoteList = second.docs.map(doc => doc.data());
      return quoteList;
  }

export { app, firestore };


// CONFIG FOR THE QUOTES DATABASE
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCm6y2nrkqhGCSkX20khZ16O-wu7vmMOFA",
//   authDomain: "quotes-bfefd.firebaseapp.com",
//   projectId: "quotes-bfefd",
//   storageBucket: "quotes-bfefd.appspot.com",
//   messagingSenderId: "392243974885",
//   appId: "1:392243974885:web:6643fdb7aaf30d70f4ba0c",
//   measurementId: "G-XS1ERWPFXZ"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);