import React from "react"
import { useEffect, useState } from 'react';
import { firestore } from '../firebase/config';
import { BookCard } from "../components/card/BookCard";
import { collection, getDocs } from "firebase/firestore";



function All() {
    const [quotes, setQuotes] = useState([]);

    const fetchQuotes = async () => {
        const req = collection(firestore, 'quotes');
        const second = await getDocs(req);
        const quoteList = second.docs.map(doc => doc.data());
        setQuotes(quoteList);
    }

    useEffect(() => {
    fetchQuotes();
    }, [])

    return(
        <div className="mainContainer">
            <div className="innerContainer">
                <div>
                    {quotes.map((quote, i)=>(
                        <BookCard key={i} {...quote}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default All;